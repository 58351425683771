<template>
  <div class="page-404-wrapper">404</div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.page-404-wrapper {
  margin-top: 200px;
  font-size: 54px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
</style>
